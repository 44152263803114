<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'" no-gutters>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" class="leftSection">
        <h3 v-bind:class="mobile ? 'subTile1Mobile' : 'subTile1'" v-html="$t('subTitle1')"></h3>
        <div class="text" v-html="$t('text1')"></div>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" align-self="center">
        <v-img
            v-bind:class="mobile ? 'imgFirstSectionMobile' : 'imgFirstSection'"
            :src="require('@/assets/enterprise/enterprise1.png')"
            contain
            v-bind:height="mobile ? 250 : 350"
        />
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
      <h1 v-bind:class="mobile ? 'titleSecondSectionMobile' : 'titleSecondSection'" v-html="$t('titleSecond')"></h1>
      <v-col v-bind:cols="mobile ? 10 : 5">
        <div v-html="$t('textLeft')"></div>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5">
        <br v-if="mobile">
        <div v-html="$t('textRight')"></div>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'AwarenessDayComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Sport health well-being awareness day",
    "subTitle1": "A day to take stock of your health!",
    "text1": "<br> Meet our health and well-being professionals At a time when the renunciation of healthcare is increasingly important among our fellow citizens, <strong> the objective is to provide everyone with access to prevention and health screening. </strong> <br> For the association, this is a way of fighting against social inequalities in health for people with little or no access to rights and care. <br> <br> The sport health well-being awareness day is a day of identification and proximity during which are offered to people, various preventive consultations and personalized health screening. The originality of this action is that it is offered on the same day, in the same place and with local health professionals. <br> <br> <strong> These days will consist of conferences, stands and events. </strong>",
    "titleSecond": "The information and identification stands will be run by health professionals on the following themes :",
    "textLeft": "<h3> - Food: </h3> PNNS Council (National Health Nutrition Program) by a dietician, BMI measurement, waist circumference, possibility of registering for a nutrition workshop. <br> <br> <h3> - Physical activity: </h3> PNNS advice by a sports educator, fitness test, club directory, possibility of registration. <br> <br> <h3> - Thesis: </h3> Advice from a psychologist , Fluidity drums exercises for the elderly using the Liliane Israël method, possibility of registering for a memory workshop. <br> <br> <h3> - Vision: </h3> Visual acuity test carried out by an optician to measure visual acuity (ability to distinguish more or less small elements such as shapes, numbers, letters…), referral to a health service.",
    "textRight": "<h3> - Hearing: </h3> Measurement by an audioprosthetist of the hearing capacity by an audioprosthetist, referral to a health service. <br> <br> <h3> - Oral hygiene: </h3> Advice and oral screening performed by a dentist. <br> <br> <h3> - Diabetes screening performed by a nurse. </h3> <br> <br> <h3> - Information on cancer screening (breast and colorectal) by organized cancer screening management centers. </h3> <br> <h3> - Access to health rights: </h3> Advice and guidance."
  },
  "fr": {
    "mainTitle": "Journée de sensibilisation sport santé bien-être",
    "subTitle1": "Une journée pour faire le point sur sa santé !",
    "text1": "<br> Rencontrez nos professionnels de la santé et du bien-être à l’heure où le renoncement aux soins est de plus en plus important chez nos concitoyens, <strong>l’objectif est de permettre à tous d’accéder à la prévention et au dépistage de santé. </strong> <br> Pour l’association, c’est une façon de lutter contre les inégalités sociales de santé pour les personnes n’ayant pas ou peu accès aux droits et aux soins. <br><br> La journée de sensibilisation sport santé bien être, est une journée de repérage et de proximité durant laquelle sont proposés aux personnes, diverses consultations de prévention et de dépistages santé personnalisées. L’originalité de cette action est de proposer cela le même jour, dans un même lieu et avec des professionnels de la santé locaux. <br><br> <strong>Ces journées seront composées de conférences, de stands et d’animations.</strong>",
    "titleSecond": "Les stands d’information et de repérage seront animés par des professionnels de santé sur les thématiques suivantes :",
    "textLeft": "<h3>- Alimentation : </h3> Conseil PNNS (Programme national Nutrition Santé) par une diététicienne, mesure de l’IMC, tour de taille, possibilité d’inscription à un atelier nutrition.<br><br><h3> – Activité physique : </h3> Conseil PNNS par un éducateur sportif, test de forme, annuaire des clubs, possibilité d’inscription.<br><br><h3> - Mémoire :</h3> Conseil d’un psychologue, Exercices Batterie de fluidité pour personnes âgées de la méthode Liliane Israël, possibilité d’inscription à un atelier mémoire. <br><br><h3> – Vue : </h3> Test d’acuité visuelle réalisé par un opticien qui permet de mesurer l’acuité visuelle (capacité à distinguer des éléments plus ou moins petits comme des formes, des chiffres, des lettres…), orientation vers un service de santé.",
    "textRight": "<h3> - Audition : </h3> Mesure de la capacité auditive par un audioprothésiste, orientation vers un service de santé.<br><br><h3> - Hygiène bucco-dentaire : </h3> Conseil et dépistage bucco-dentaire réalisé par un dentiste. <br><br><h3>– Dépistage du diabète réalisé par une infirmière. </h3> <br><br><h3>- Informations sur les dépistages du cancer (sein et colorectal) par les centres de gestion des dépistages organisés des cancers. </h3><br><h3> - Accès aux droits à la santé : </h3> Conseils et orientation."
  }
}
</i18n>

<style scoped>

.firstSection {
  padding-top: 60px;
  padding-bottom: 60px;
}

.firstSectionMobile {
  font-size: 14px;
  padding-bottom: 40px;
}

.text {
  color: #808080;
}

.subTile1 {
  color: #1579A7;
  font-size: 20px;
  font-family: Comfortaa, sans-serif;
}

.subTile1Mobile {
  color: #1579A7;
  font-size: 16px;
  font-family: Comfortaa, sans-serif;
}

.leftSection {
  padding-left: 200px;
}

.imgFirstSection {
  margin-right: 100px;
}

.imgFirstSectionMobile {
}

.secondSection {
  font-family: Comfortaa, sans-serif;
  background-color: #1579A7;
  padding: 40px 200px 80px;
  color: #FFFFFF;
}

.secondSectionMobile {
  font-size: 14px;
  font-family: Comfortaa, sans-serif;
  background-color: #1579A7;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #FFFFFF;
}

.titleSecondSection {
  padding-bottom: 80px;
}

.titleSecondSectionMobile {
  text-align: left;
  padding-left: 30px;
  padding-right: 15px;
  font-size: 20px;
  padding-bottom: 40px;
}

</style>
