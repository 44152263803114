<template>
  <div>
    <AwarenessDayComp v-bind:mobile="mobile"/>
    <RedirectEnterpriseComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import AwarenessDayComp from '@/components/desktop/Enterprise/AwarenessDayComp'
import RedirectEnterpriseComp from '@/components/desktop/Enterprise/RedirectEnterpriseComp'

  export default {
    name: 'AwarenessDay',
    props: ['mobile'],
    components: {
      AwarenessDayComp,
      RedirectEnterpriseComp
    },
  }
</script>
